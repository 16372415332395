import { TrashIcon } from '@heroicons/react/24/outline'
import { svgLoading } from 'stories/assets'
import { Button } from 'stories/components'
import { getPrice2decimal } from 'utils'

export function HUD1_800({
  inputs,
  data,
  ignoreEror,
  isNewFeeLoading,
  renderSectionHeader,
  renderHUD1Input,
  calculateValue,
  setIsNewFeeLoading,
  setIsAddCCModalOpen,
  onFeeDelete,
  isHud1a,
}: {
  inputs: Record<string, any>
  data: Record<string, any>
  ignoreEror: boolean
  isNewFeeLoading: boolean
  renderSectionHeader: Function
  renderHUD1Input: Function
  calculateValue: Function
  setIsNewFeeLoading: Function
  setIsAddCCModalOpen: Function
  onFeeDelete: Function
  isHud1a: boolean
}) {
  return (
    <>
      {renderSectionHeader('800. Items Payable in Connection with Loan')}
      {inputs['originationCharge']?.map((item: any) => {
        const { key, error, lineIdKey, title, ccid } = item
        if (error && !ignoreEror) return
        return (
          <tr>
            <td className="p-2" key={key}>
              {renderHUD1Input(item, key)}
            </td>
            <td></td>
            {!isHud1a && ccid && (
              <td className="p-2">
                {' '}
                <span
                  className="text-red-800 cursor-pointer flex items-center justify-end"
                  onClick={() => onFeeDelete(ccid, data[lineIdKey], title)}
                >
                  <TrashIcon className="w-4 h-4" />
                </span>
              </td>
            )}
          </tr>
        )
      })}
      {inputs['802']?.map((item: any) => {
        const { key, error, lineIdKey, title, ccid } = item
        if (error && !ignoreEror) return
        return (
          <tr>
            <td className="p-2" key={key}>
              {renderHUD1Input(item, key)}
            </td>
            <td></td>
            {!isHud1a && ccid && (
              <td className="p-2">
                {' '}
                <span
                  className="text-red-800 cursor-pointer flex items-center justify-end"
                  onClick={() => onFeeDelete(ccid, data[lineIdKey], title)}
                >
                  <TrashIcon className="w-4 h-4" />
                </span>
              </td>
            )}
          </tr>
        )
      })}
      {inputs['802Desc']?.map((item: any) => {
        const { key } = item
        return (
          <tr>
            <td className="p-2" key={key}>
              {renderHUD1Input(item, key)}
            </td>
          </tr>
        )
      })}
      <tr>
        {inputs['803']?.map((item: any) => {
          const { key, title, valueKey } = item
          return (
            <>
              <td className="p-2">
                {key}. {title}
              </td>
              <td className="p-2">{getPrice2decimal(calculateValue(valueKey), false, true)}</td>
            </>
          )
        })}
      </tr>
      {inputs['additionalFees']?.map((item: any) => {
        const { key, error, lineIdKey, title, ccid } = item
        if (error && !ignoreEror) return
        return (
          <tr>
            <td className="p-2" key={key}>
              {renderHUD1Input(item, key)}
            </td>
            <td className="p-2 ">
              {!data[item['pocKey']]
                ? getPrice2decimal(+data[item['feeBKey']] + +data[item['feeSKey']], false, true)
                : ''}
            </td>
            {!isHud1a && ccid && (
              <td className="p-2">
                {' '}
                <span
                  className="text-red-800 cursor-pointer flex items-center justify-end"
                  onClick={() => onFeeDelete(ccid, data[lineIdKey], title)}
                >
                  <TrashIcon className="w-4 h-4" />
                </span>
              </td>
            )}
          </tr>
        )
      })}
      <tr>
        <td className="flex items-center">
          <Button
            link
            color="blue"
            onClick={() => {
              setIsNewFeeLoading(true), setIsAddCCModalOpen(true)
            }}
            disabled={isNewFeeLoading}
          >
            Add new fee
          </Button>
          {isNewFeeLoading && (
            <span className="ml-3">
              <img src={svgLoading} className="inline w-4 h-4 text-white animate-spin" />
            </span>
          )}
        </td>
      </tr>
    </>
  )
}
