import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import { usePermissions } from 'hooks/usePermissions'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getSitexProReport, isVendorEnabled } from 'services'
import { getItemsFromFullAddress } from 'utils'
import { confirmOptions } from 'utils/modals/confirmOptions'

import { LayoutLoading } from './LayoutLoading'

interface SitexProLocation {
  FIPS: string
  APN: string
  Address: string
  City: string
  State: string
  ZIP: string
  ZIP4: string
  UnitType: string
  UnitNumber: string
  UseCode: string
  UseCodeDescription: string
  Owner: string
}

export const SitexProLink = ({ address }: { address: string }) => {
  const [isEnabled, setEnabled] = useState(false)
  const [isLoading, setLoading] = useState(false)

  const { hasPermission } = usePermissions()
  const canViewSitexPro = hasPermission('CAN_VIEW_SITEXPRO')

  useEffect(() => {
    isVendorEnabled('SitexPro').then(setEnabled)
  }, [])

  const onClick = async () => {
    if (!address) return
    setLoading(true)

    const addressObj = await getItemsFromFullAddress(address)
    let { success, locations } = (await getSitexProReport({
      address: addressObj.street_address1,
      lastLine: addressObj.postal_code,
    })) as {
      success: boolean
      locations: SitexProLocation[]
    }

    if (!success && locations) {
      const options = locations.map((v) => {
        const addr = `${v.Address} ${v.UnitType} ${v.UnitNumber}`
        return {
          name: `${v.Owner} - ${addr}`,
          value: addr,
          desc: `${v.APN}, ${v.UseCodeDescription}`,
        }
      })
      const result: any = await confirmOptions('Select Address', options)
      if (result)
        ({ success } = await getSitexProReport({
          address: result,
          lastLine: addressObj.postal_code,
        }))
    }
    success && toast(`We've started to generate SitexPro Report and it will take few seconds.`, { type: 'info' })
    setLoading(false)
  }

  if (!isEnabled) return null

  return (
    <>
      {canViewSitexPro && (
        <div className="flex items-center justify-end gap-1 text-shade-blue hover:underline cursor-pointer">
          <span className="font-semibold" onClick={onClick}>
            SitexPro
          </span>
          <ArrowDownTrayIcon className="w-3 h-3" />

          {isLoading && (
            <div className={`fixed inset-0 z-[999] bg-white/50`}>
              <LayoutLoading show />
            </div>
          )}
        </div>
      )}
    </>
  )
}
