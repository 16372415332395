import { HUD1InputType } from '../HUD1Page1/constant'

export function HUD1_700({
  inputs,
  data,
  renderSectionHeader,
  renderHUD1Input,
  onChange,
  showHistory,
  setFocusedKey,
  showInputValue,
}: {
  inputs: Record<string, any>
  data: Record<string, any>
  renderSectionHeader: Function
  renderHUD1Input: Function
  onChange: Function
  showHistory: Function
  setFocusedKey: Function
  showInputValue: Function
}) {
  return (
    <>
      {renderSectionHeader('700. Total Real Estate Broker Fees')}
      <tr>
        <td className="p-2 pl-12">Division of commission (line 700) as follows:</td>
      </tr>
      {inputs['brokerFee']?.map((item: any) => {
        let content: any = ''
        const { no, title, key, type, descKey, pbaKey, pbsKey } = item
        if (type == HUD1InputType.inputParty) {
          content = [<td key={1}>{renderHUD1Input(item, key)}</td>, <td key={2}></td>, <td key={3}></td>]
        } else {
          content = [
            <td key={1}>
              <div className="flex items-center gap-2">
                <div
                  className="w-[28px] cursor-pointer hover:underline"
                  onClick={() => showHistory([descKey, pbaKey, pbsKey].filter((v) => !!v))}
                >
                  {no}.
                </div>
                {title && <span>{title}</span>}
                {descKey && (
                  <input
                    className="px-2 border w-full"
                    value={data[descKey]}
                    onChange={(e) => onChange(descKey, e.target.value)}
                  />
                )}
              </div>
            </td>,
            <td key={2} className="px-2">
              <input
                className="px-1 text-right border w-[100px]"
                value={showInputValue(data[pbaKey], pbaKey)}
                onBlur={() => setFocusedKey('')}
                onFocus={() => setFocusedKey(pbaKey)}
                onChange={(e) => onChange(pbaKey, e.target.value, 'number')}
              />
            </td>,
            <td key={3} className="px-2">
              <input
                className="px-1 text-right border w-[100px]"
                value={showInputValue(data[pbsKey], pbsKey)}
                onBlur={() => setFocusedKey('')}
                onFocus={() => setFocusedKey(pbsKey)}
                onChange={(e) => onChange(pbsKey, e.target.value, 'number')}
              />
            </td>,
          ]
        }
        return <tr key={item.no}>{content}</tr>
      })}
      <tr>
        <td className="py-2"></td>
      </tr>
    </>
  )
}
